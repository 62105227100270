"use client";

import Image from "next/image";
import styles from "./index.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import clsx from "clsx";
import { useEffect, useState } from "react";

const IMAGES = [
  "/banner/top-fv/AImarketer_2410.webp",
  "/icon/AIer-SecondLayer/other/TED_TOP.webp",
];

// 各画像に対応する遷移先のリンクを定義
const LINKS = [
  "https://www.gen-ai-marketing.com/", // 1枚目の画像の遷移先
  "/business/TED", // 2枚目の画像の遷移先
];

export const FirstViewSlider = () => {
  const [imageIndexes, setImageIndexes] = useState<number[] | null>(null);

  useEffect(() => {
    const randomStartNumber = Math.floor(Math.random() * IMAGES.length);
    const _imageIndexes = [...Array(IMAGES.length)].map((_, index) => {
      if (index + randomStartNumber >= IMAGES.length) {
        return index + randomStartNumber - IMAGES.length;
      } else {
        return index + randomStartNumber;
      }
    });
    setImageIndexes(_imageIndexes);
  }, []);

  if (!imageIndexes)
    return (
      <div className={styles.slider}>
        <div className={styles.sliderItem} />
      </div>
    );

  return (
    <Swiper
      loop
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      className={clsx(styles.slider, "top-fv-slider")}
      autoplay={{ delay: 6000 }}
    >
      {imageIndexes.map((imageIndex) => (
        <SwiperSlide className={styles.sliderItem} key={imageIndex}>
          <a href={LINKS[imageIndex]} target="_blank" rel="noopener noreferrer">
            <Image
              src={IMAGES[imageIndex]}
              alt=""
              fill
              className="u-fill-image"
              priority
              loading="eager"
            />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
