"use client";

import Image from "next/image";
import styles from "./index.module.scss";
import { MyLink } from "@/components/MyLink";
import { useRef } from "react";
import Link from "next/link";

export const BottomBanner2 = () => {
  const ref = useRef<HTMLDivElement>(null);
  const onClickClose = () => {
    if (!ref.current) return;
    ref.current.style.display = "none";
  };
  return (
    <div className={styles.bottomBanner} ref={ref}>
      <button onClick={onClickClose} aria-label="閉じる">
        <span>
          <span></span>
          <span></span>
        </span>
      </button>
      <div className={styles.box}>
        <p className={styles.topText}>AI総研についてもっと知る</p>
        <ul className={styles.list}>
          <li className={styles.item}>
            <MyLink href="/resources/aisouken-3document" className={styles.itemLink}>
              3点セットをダウンロードする
            </MyLink>
          </li>
          <li className={styles.item}>
            <MyLink href="/resources/aisouken-introduction-resources" className={styles.itemLink}>
              AI総研紹介資料をダウンロードする
            </MyLink>
          </li>
          <li className={styles.item}>
            <MyLink href="/resources" className={styles.itemLink}>
              プロンプト集をダウンロードする
            </MyLink>
          </li>
        </ul>
        {/* <Link href="" className={styles.bottomLink}>
          全ての資料を一括請求する
        </Link> */}
      </div>
    </div>
  );
};
