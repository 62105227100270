"use client";

import Image from "next/image";
import styles from "./index.module.scss";
import { useEffect, useRef } from "react";

export const FadeLogoSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(doWhenIntersect, {});
    observer.observe(ref.current);

    function doWhenIntersect(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.isActive);
        }
      });
    }
  }, []);

  return (
    <section className={styles.fadeLogoSection}>
      <div className={styles.sectionInner}>
        <h2 className={styles.fadeLogoTitle}>
          <span>最先端AIモデル</span>による、企業の変革を支援。
        </h2>
        <div className={styles.fadelogoListWrap} ref={ref}>
          <ul className={styles.fadelogoList}>
            <li>
              <Image
                src="/logo/azure-logo.svg"
                alt="azure-logo"
                width="201"
                height="58"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/gcp-logo.webp"
                alt="gcp-logo"
                width="384"
                height="142"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/meta-logo.svg"
                alt="meta-logo"
                width="948"
                height="191"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/aws-logo.svg"
                alt="aws-logo"
                width="251"
                height="150"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
          </ul>
          <ul className={styles.fadelogoList}>
            <li>
              <Image
                src="/logo/OpenAI-logo.webp"
                alt="OpenAI-logo"
                width="384"
                height="95"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/mistral-logo.webp"
                alt="mistral-logo"
                width="256"
                height="82"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/mosaic-logo.svg"
                alt="mosaic-logo"
                width="1161"
                height="200"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
            <li>
              <Image
                src="/logo/Anthropic-logo.svg"
                alt="Anthropic-logo"
                width="1024"
                height="115"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export const FadeLogoSection2 = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(doWhenIntersect, {});
    observer.observe(ref.current);

    function doWhenIntersect(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.isActive2);
        }
      });
    }
  }, []);
  return (
    <section className={styles.fadeLogoSection}>
      <div className={styles.sectionInner}>
        <h2 className={styles.fadeLogoTitle}>
          <span>Our Backend</span>
        </h2>
        <div className={styles.fadelogoListWrap} ref={ref}>
          <ul className={styles.fadelogoList2}>
            <li>
              <Image
                src="/logo/MS_Startups_Celebration_Badge_Light.webp"
                alt="Microsoft_Startups-logo"
                width="300"
                height="150"
                className="u-doubled-image"
                loading="eager"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
