import Link from "next/link";
import { CSSProperties, MouseEventHandler } from "react";

type Props = {
  children: React.ReactNode;
  href: string;
  className?: string;
  onMouseOver?: MouseEventHandler<HTMLAnchorElement>;
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
  style?: CSSProperties;
  passHref?: boolean;
};

export const MyLink = (props: Props) => {
  const {
    children,
    href,
    className,
    onMouseOver,
    onMouseLeave,
    style,
    passHref,
  } = props;
  return (
    <Link
      href={href}
      className={className}
      prefetch={false}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={style}
      passHref={passHref}
    >
      {children}
    </Link>
  );
};
